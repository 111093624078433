import React, { useState } from 'react';
import FilterPopup from './FilterPopup';

const FilterButton = ({ applyFilters, currentFilters, onFiltersChange }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  return (
    <div>
      <button onClick={togglePopup} className= {showPopup ?'filter-button-in-popup':'filter-button'}>Filter</button>
      {showPopup && 
        <FilterPopup 
          applyFilters={applyFilters} 
          closePopup={togglePopup} 
          currentFilters={currentFilters} // Ensure this is coming from the parent
          onFiltersChange={onFiltersChange} // Pass down the function
        />
      }
    </div>
  );
};

export default FilterButton;
