import React from 'react'
import FirebaseSignIn from './FirebaseSignIn'
import './SignInPrompt.css'
import { isMobile } from 'react-device-detect';

const SignInPrompt = ({closeSignInPrompt}) => {
  return (
    <div className='sign-in-prompt'>
      {isMobile?(
        <div className='sign-in-prompt-inner-mobile'>
            <h2>Hold on!</h2>
            <p>To use this feature, you'll need to sign in</p>
            <br/>
            <FirebaseSignIn closeSignInPrompt={closeSignInPrompt}/>
            <br/>
            <div className='sign-in-prompt-close-button-mobile'>
                <button onClick={closeSignInPrompt}>Close</button>
            </div>

        </div>
      ):(
      <div className='sign-in-prompt-inner'>
            <h2>Hold on!</h2>
            <p>To use this feature, you'll need to sign in</p>
            <br/>
            <FirebaseSignIn closeSignInPrompt={closeSignInPrompt}/>
            <br/>
            <div className='sign-in-prompt-close-button'>
                <button onClick={closeSignInPrompt}>Close</button>
            </div>

        </div>
      )};
      
      
    </div>
  );
}

export default SignInPrompt
