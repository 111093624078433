import React, { useEffect, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import './StockScores.css';
import FilterButton from './FilterButton';
import AddTickerToWatchlist from './AddTickerToWatchlist';
import RemoveFromWatchlist from './RemoveFromWatchlist';
import {auth,db,app} from '../components/FirebaseSetUp';
import {doc, getDoc, FieldValue,updateDoc,arrayUnion,setDoc} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged
import { CiCircleInfo } from "react-icons/ci";
import LoadingIcons from 'react-loading-icons'
import { size } from 'lodash';
import StockScoreInfoPopup from './StockScoreInfoPopup';

const StockScores = ({ onStockClick,triggerSignInPrompt, triggerStockScoreInfoPopup, showOnlyWatchlist = false }) => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]); // Manage filters here
  const [watchlist,setWatchlist] = useState([]);

  // const getWatchlist = async() => {
  useEffect(() => {
    const fetchWatchlist = async (user) => {
      try {
        console.log('Found user, going to read the watchlist');
        const userDocRef = doc(db, "users", user.uid); // Reference the user's document by uid
      
        const userDocSnap = await getDoc(userDocRef); // Await the document snapshot
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setWatchlist(userData.watchlist);            
          console.log('Setting user watchlist'); // Log the user data
        } else {
          console.log('No watchlist found for the user');
        }
      } catch (error) {
        setWatchlist([])
        console.error('Error fetching user watchlist:', error);
      }
    };
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchWatchlist(user); // If user is signed in, fetch their watchlist
      } else {
        setWatchlist([])
        console.log('No user signed in for StockScores watchlist, leaving it empty');
      }
    });
    refreshWatchlist();
    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);

  useEffect(() => {
    if (watchlist.length > 0) {
      console.log('User watchlist:', watchlist); // Logs the updated watchlist
    }
  }, [watchlist]); // Trigger this effect whenever watchlist changes
  
  const refreshWatchlist = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      try {
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setWatchlist(userData.watchlist); // Update the watchlist state
        }
      } catch (error) {
        console.error("Error fetching updated watchlist:", error);
      }
    } else{
      setWatchlist([])
    }
  };
  
  // Initial watchlist fetch on component mount
  useEffect(() => {
    refreshWatchlist();
  }, []);  

  const applyFilters = (filteredResponse) => {
    setData(filteredResponse);
    setFilteredData(filteredResponse);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.stockrift.com/get-today-stockScores/');
        setData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error('Error fetching stock scores:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const debounceFilter = debounce(() => {
      const lowercasedQuery = searchQuery.toLowerCase();
      const newFilteredData = data.filter(item =>
        item[0].toLowerCase().includes(lowercasedQuery)
      );

      // Filter by watchlist if showOnlyWatchlist is true
      if (showOnlyWatchlist && watchlist.length > 0) {
        newFilteredData = newFilteredData.filter(item => watchlist.includes(item[0]));
      }

      setFilteredData(newFilteredData);
    }, 300);

    debounceFilter();

    return () => {
      debounceFilter.cancel();
    };
  }, [searchQuery, data]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowClick = (symbol) => {
    if (onStockClick) onStockClick(symbol);
  };

  const handleFiltersChange = (newFilters) => {
    setCurrentFilters(newFilters);
  };

  const getAppliedFilters = () => {
    return currentFilters.map(filter => ({
      key: filter.key,
      operator: filter.operator,
      value: filter.value,
    }));
  };

  const handleApplyFilters = async () => {
    const filtersPayload = getAppliedFilters();
    // Call your API to apply the filters and update filteredData
    // Example:
    const response = await axios.post('https://api.stockrift.com/compute-screen/', { filters: filtersPayload });
    applyFilters(response.data);
  };

  if (data.length === 0) return <div className='stockscores-loading-symbol'><LoadingIcons.Bars /></div>;

  const dataToDisplay = showOnlyWatchlist 
    ? filteredData.filter(item => watchlist.includes(item[0])) // Show only watchlist stocks
    : filteredData; // Show all stocks otherwise

  // if (showOnlyWatchlist && dataToDisplay.length === 0) return <div>Your watchlist may be empty, head to the explore tab to add to your watchlist!</div>;

  return (
    <div className="stock-scores-container">
      <div className="stock-scores-header">
        <FilterButton 
          applyFilters={handleApplyFilters} 
          currentFilters={currentFilters} 
          onFiltersChange={handleFiltersChange} 
          className = 'filter-button'
        />
        {/* only render the search bar if not showOnlyWatchlist */}
        {showOnlyWatchlist?'':
        <input
        type="text"
        placeholder="Search by ticker..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-bar"
      />}
      
        
      </div>
      <div className="stock-scores-table-container">
        <table className="stock-scores-table">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Volume</th>
              <th style={{textWrap:'balance'}}><CiCircleInfo onClick={triggerStockScoreInfoPopup} size={"13"} cursor={'pointer'}/><br/>Change % at Close </th>
              <th style={{textWrap:'balance'}} ><CiCircleInfo onClick={triggerStockScoreInfoPopup} size={"13"} cursor={'pointer'}/><br/>Entry Price </th>
              <th className='suggestion-box' style={{textWrap:'balance'}} ><CiCircleInfo onClick={triggerStockScoreInfoPopup} size={"13"} cursor={'pointer'}/><br/>Prediction </th>
              <th style={{textWrap:'balance'}} ><CiCircleInfo onClick={triggerStockScoreInfoPopup} size={"13"} cursor={'pointer'}/><br/>Prediction Accuracy</th>
              <th style={{textWrap:'balance'}}>Add to Watchlist</th>
            </tr>
          </thead>
          <tbody>
            {/* {filteredData.map((item, index) => { */}
            {dataToDisplay.map((item, index) => {
              const [symbol, volume, price, change, stockScore,volOverPrice,accuracy] = item;
              const roundedPrice = price.toFixed(2);
              const multipliedChange = (change * 100);
              const percentageChange = multipliedChange.toFixed(2);
              const percColorClass = 
                percentageChange < 0 ? 'percent-change-negative' 
                : percentageChange > 0 ? 'percent-change-positive' 
                : 'percent-change-neutral';
              const scoreClass = 
                stockScore < 0 ? 'stock-score-negative' 
                : stockScore > 0 ? 'stock-score-positive' 
                : 'stock-score-neutral';
              const suggestion = 
                stockScore < 0 ? 'SELL' 
                : stockScore > 0 ? 'BUY' 
                : 'HOLD';
              const accuracyClass = 
                accuracy > 66 ? 'percent-change-positive'
                : accuracy < 34 ? 'percent-change-negative'
                : 'percent-change-neutral';
              const percAccuracy = accuracy.toFixed(1);
              return (
                <tr key={index} onClick={() => handleRowClick(symbol)}>
                  <td className='stock-scores-normal-color'><button className='ticker-button'>{symbol}</button></td>
                  <td className='stock-scores-normal-color'>{volume}</td>
                  <td className={percColorClass}>{percentageChange}%</td>
                  <td className='stock-scores-normal-color'>{roundedPrice}</td>
                  <td className={scoreClass}>{suggestion}</td>
                  <td className={accuracyClass}>{percAccuracy}%</td>
                  { watchlist.includes(symbol)?(
                    <td className='stock-scores-normal-color'><RemoveFromWatchlist ticker={symbol} triggerSignInPrompt = {triggerSignInPrompt} refreshWatchlist={refreshWatchlist}/> </td >
                  ):(
                    <td className='stock-scores-normal-color'><AddTickerToWatchlist ticker={symbol} triggerSignInPrompt = {triggerSignInPrompt} refreshWatchlist={refreshWatchlist}/> </td >
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StockScores;
