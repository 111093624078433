import React from "react";
import { useState,useEffect  } from 'react';
import { Analytics } from '@vercel/analytics/react';
import './Sandbox.css';
import ConnectBrokerage from "../components/ConnectBrokerage";
import {auth,db,app} from '../components/FirebaseSetUp'
import FirebaseSignIn from '../components/FirebaseSignIn';
import { getFirestore } from "firebase/firestore";

function Sandbox()  {

    const [user, setUser] = useState(null);
    const [db,setDb] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state for user auth

    // Listen to the authentication state and set the user
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setDb(getFirestore(app))
            setLoading(false); // Set loading to false once we know the user state
        });

        // Cleanup the subscription on unmount
        return () => unsubscribe();
    }, []);


    return (
        <div className="SignUp-main">
            <h1>
                Link Brokerage
            </h1>
            <div className="sign-up-container">

                {user ? 
                    <>
                        {/* <ConnectBrokerage/> */}
                        <h3>Welcome {user.email}!</h3>
                    </>
                    :
                    <>
                    <div className="dashboard-sign-in-prompt">
                        <h2>Welcome, please sign in!</h2>
                        <FirebaseSignIn/>
                    </div>
                    </>
                }
            </div>
        <Analytics mode={'production'} />
        </div>
    );
}
 
export default Sandbox;