import React from "react";
import { Analytics } from '@vercel/analytics/react';
import './SignUp.css';

function SignUp()  {
    return (
        <div className="SignUp-main">
            <h1>
                Sign Up
            </h1>
            <div className="sign-up-container">
                <div className="input-section">
                    <h3>Email </h3>
                    <input type="email" style={{ height: '40px', fontSize: '18px' }}></input>
                    
                </div>
                <div className="input-section">
                    <h3>Password </h3>
                    <input type="password" style={{ height: '40px', fontSize: '18px' }}></input>
                </div>
                <div className="input-section">
                    <h3>Password (Again) </h3>
                    <input type="password" style={{ height: '40px', fontSize: '18px' }}></input>
                </div>
                <div className="submit">
                    <button className="submit-button">Submit</button>
                </div>
            </div>
        <Analytics mode={'production'} />
        </div>
    );
}
 
export default SignUp;