import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FirebaseSignIn from './FirebaseSignIn';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import './Header.css'

function Header() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user); // User is signed in
      } else {
        setCurrentUser(null); // No user is signed in
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setCurrentUser(null); // User is signed out
      })
      .catch((error) => {
        console.error('Sign-out error:', error);
      });
  };

  return (
    <div className='header'>
      
      <div className='title-and-login'>
        <Link to="/App">
        <h1>StockRift</h1>
        </Link>
        <div className='login'>
          
        {currentUser ? (
          <div className='signed-in-user'>
          <p>{currentUser.email}</p>

          <button onClick={handleSignOut} style={{ width:'100%',marginLeft: '1%' ,height:'20%',marginTop:'12%'}}>Sign-Out</button>
          </div>
        ) : (
          <FirebaseSignIn setCurrentUser={setCurrentUser} />
        )}
        </div>
      </div>
      <div className='header-links'>
        <Link to="/App" className='header-links-sub'>
          <h3 className='header-links-sub'>Explore</h3>
        </Link>
        <Link to="/dashboard" className='header-links-sub'>
          <h3 className='header-links-sub'>My Dashboard</h3>
        </Link>
        <Link to="/about" className='header-links-sub'>
          <h3 className='header-links-sub'>About Us</h3>
        </Link>
      </div>
    </div>
  );
}

export default Header;