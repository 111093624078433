import React, { useState, useEffect } from 'react';

const FilterPopup = ({ applyFilters, closePopup, currentFilters, onFiltersChange }) => {
  const [filters, setFilters] = useState(currentFilters || []);
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [useCustomValue, setUseCustomValue] = useState(false);
  const [filterKeys, setFilterKeys] = useState([]);
  const operators = ['>', '>=', '<', '<=', '=='];

  useEffect(() => {
    const fetchFilterKeys = async () => {
      try {
        const response = await fetch('https://api.stockrift.com/get-screen-filter-keys/');
        const data = await response.json();
        setFilterKeys(data);
      } catch (error) {
        console.error('Error fetching filter keys:', error);
      }
    };

    fetchFilterKeys();
  }, []);

  useEffect(() => {
    setFilters(currentFilters); // Update filters when currentFilters prop changes
  }, [currentFilters]);

  const handleKeyChange = (e) => setSelectedKey(e.target.value);
  const handleOperatorChange = (e) => setSelectedOperator(e.target.value);
  const handleInputValueChange = (e) => setInputValue(e.target.value);
  const handleCustomValueToggle = (e) => setUseCustomValue(e.target.checked);

  const handleAddFilter = () => {
    if (selectedKey && selectedOperator && inputValue) {
      const newFilter = {
        key: selectedKey,
        operator: selectedOperator,
        value: inputValue,
      };
      const updatedFilters = [...filters, newFilter];
      setFilters(updatedFilters);
      onFiltersChange(updatedFilters); // Notify parent of the change
      setSelectedKey('');
      setSelectedOperator('');
      setInputValue('');
      setUseCustomValue(false);
    }
  };

  const handleRemoveFilter = (index) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
    onFiltersChange(updatedFilters); // Notify parent of the change
  };

  const handleSubmit = async () => {
    const filterPayload = { filters };

    try {
      const response = await fetch('https://api.stockrift.com/compute-screen/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filterPayload),
      });

      if (response.ok) {
        const responseData = await response.json();
        applyFilters(responseData);
      } else {
        console.error('Error in POST request:', response.status);
      }
    } catch (error) {
      console.error('Error in submitting filters:', error);
    }

    closePopup(); // Close the popup after submitting
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Choose Filters</h3>
        
        <div className='filter-option-dropdowns'>
        {/* <label>Select Filter Key</label> */}
        <select name="filterKey" value={selectedKey} onChange={handleKeyChange}>
          <option value="" className='filter-option-dropdowns'>Select a filter key</option>
          {filterKeys.map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
        </div>

        <div className='filter-option-dropdowns'>
        <select name="filterOperator" value={selectedOperator} onChange={handleOperatorChange} className='filter-option-dropdowns'>
          <option value="" className='filter-option-dropdowns'>Select an operator</option>
          {operators.map((op) => (
            <option key={op} value={op} className='filter-option-dropdowns'>
              {op}
            </option>
          ))}
        </select>
        </div>
        

        <div className='filter-option-dropdowns'>
        {!useCustomValue ? (
          <select name="filterValue" value={inputValue} onChange={handleInputValueChange}>
          <option value="" className='filter-option-dropdowns'>Select a value</option>
          {filterKeys.map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
          
        ) : (
          
          <input
          type="text"
          name="filterValue"
          value={inputValue}
          onChange={handleInputValueChange}
          placeholder="Type a custom value"
          className='filter-button-in-popup'
        />
        )}
        </div>
        <div className='filter-option-dropdowns'>
        <label>
          <input type="checkbox" checked={useCustomValue} onChange={handleCustomValueToggle} />
          Use custom value
        </label>
        </div>
        <div>
          <button onClick={handleAddFilter} className='filter-button-in-popup'>Add Filter</button>
        </div>
        
        
        <div className="applied-filters">
          <h4>Applied Filters:</h4>
          {filters.map((filter, index) => (
            <div key={index}>
              {filter.key} {filter.operator} {filter.value}
              <button onClick={() => handleRemoveFilter(index)} className='remove-button'>Remove</button>
            </div>
          ))}
        </div>

        <button onClick={handleSubmit} className='filter-button-in-popup'>Apply</button>
        <button onClick={closePopup} className='filter-button-in-popup'>Cancel</button>
      </div>
    </div>
  );
};

export default FilterPopup;
