import React, { useEffect, useRef, memo } from 'react';

const StockGraph = ({ selectedStock }) => {
    const container = useRef();

    useEffect(() => {
        //if (!selectedStock) return; // Prevent rendering if no stock is selected

        // Clear previous widget content
        container.current.innerHTML = "";

        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = JSON.stringify({
            autosize: true,
            symbol: selectedStock,
            interval: "D",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            allow_symbol_change: true,
            calendar: false,
            support_host: "https://www.tradingview.com"
        });

        container.current.appendChild(script);

        // Cleanup function to remove the widget when the component unmounts or `selectedStock` changes
        return () => {
            if (container.current) {
                container.current.innerHTML = "";
            }
        };
    }, [selectedStock]); // Ensure effect runs when `selectedStock` changes

    return (
        <div className="tradingview-widget-container" ref={container}>
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
              <span className="blue-text">Track all markets on TradingView</span>
            </a>
          </div>
        </div>
    );
};

{/*  */}

export default memo(StockGraph);
