import React from "react";
import Sidebar from "../components/Sidebar";
import './About.css';
import JotformEmbed from 'react-jotform-embed';
import { Analytics } from '@vercel/analytics/react';


function About()  {
    return (
        <div className="About-main">
            <h1>
                    StockRift is a stock prediction and analysis tool, aiming to streamline resarch for traders.
            </h1>
            <div className="written-text">
                
                <p>Hi my name is Emad and I am making my dream stock-analysis and prediction app. 
                Whether you love this app or wish that it had something that it doesnt, please give me feedback
                below so that I can improve the experience and hopefully make something that
                anyone who likes trading will love to use.<br />
                <br />
                The goal of this site is to predict stock movements on the trading day before. We give each stock a score based on
                candlestick patterns and analyst recommendations. <br />
                <br />
                Future features inlcude alerting at the 4pm bell so users can enter trades in after hours trading and personalized dashboards.<br />
                <br />
                Again, thanks for coming to this site and please leave any feedback or requests please fill out the quick form below.</p>
                
            </div>
            <div className = "jotform">
                <JotformEmbed src="https://form.jotform.com/242587101163148"/>
            </div>
        <Analytics mode={'production'} />
        </div>
    );
}
 
export default About;