import React, { useState,useEffect } from 'react';
import './App.css';
import { Analytics } from "@vercel/analytics/react"
import Header from './components/Header';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation 
} from "react-router-dom";
// import Sidebar from './components/Sidebar';
import StockScores from './components/StockScores';
import StockGraph from './components/StockGraph';
import HistoricalTable from './components/HistoricalTable';
// import { Scrollbar } from 'react-scrollbars-custom';
import About from './pages/About';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import Sandbox from './pages/Sandbox';

import { isMobile } from 'react-device-detect';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, getRedirectResult, signOut, GoogleAuthProvider } from "firebase/auth";
import SignInPrompt from './components/SignInPrompt';
// import InitialSignInPrompt from './components/InitialSignInPrompt';

import StockScoreInfoPopup from './components/StockScoreInfoPopup';

import {auth,db,app} from './components/FirebaseSetUp'
import {useAuthState} from 'react-firebase-hooks/auth'
// import {useCollectionData} from 'react-firebase-hooks/firestore'


const App = () => {
  const [selectedStock, setSelectedStock] = useState('AAPL');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [signInPrompt, setSignInPrompt] = useState(false);

  const [user, setUser] = useState(null);
  const [initialSignInPrompt, setInitialSignInPrompt] = useState(null);

  const [stockScoreInfoPopup, setStockScoreInfoPopup] = useState(false);

  const handleStockClick = (stock) => {
    setSelectedStock(stock);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const turnOffSidebar = () => {
    setIsSidebarOpen(false);
  };
  // Get the current route location
  const location = useLocation();
  
  const triggerSignInPrompt = () => {
    setSignInPrompt(true);
  };
  const closeSignInPrompt = () => {
    setSignInPrompt(false);
  };

  // const triggerInitialSignInPrompt = () => {
  //   setInitialSignInPrompt(true);
  // };
  // const closeInitialSignInPrompt = () => {
  //   setInitialSignInPrompt(false);
  // };

  const triggerStockScoreInfoPopup = () => {
    setStockScoreInfoPopup(true);
  };
  const closeStockScoreInfoPopup = () => {
    setStockScoreInfoPopup(false);
  };

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((currentUser) => {
  //       setUser(currentUser);
  //       {currentUser ?
  //       setInitialSignInPrompt(false)
  //       :setInitialSignInPrompt(true)};
  //   });

  //   // Cleanup the subscription on unmount
  //   return () => unsubscribe();
  // }, []);
  

  return (
    <div className="App">
      <Header/>
      {/* <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/> */}
      <div>
      <Routes>
        {/* <Route exact path="/App" element={<App/>}{...Sidebar.isOpen = useState(false)} /> */}
        <Route path="/about" element={<About/>} />
        <Route path = '/sign-up' element= {<SignUp/>}/>
        <Route path = '/dashboard' element= {<Dashboard/>}/>
        <Route path = '/sandbox' element= {<Sandbox/>}/>
      </Routes>
      </div>
      
      {/* {initialSignInPrompt ? <InitialSignInPrompt closeInitialSignInPrompt = {closeInitialSignInPrompt}/>:''} */}
      {signInPrompt ? <SignInPrompt closeSignInPrompt = {closeSignInPrompt}/>:''}
      {stockScoreInfoPopup ? <StockScoreInfoPopup closeStockScoreInfoPopup = {closeStockScoreInfoPopup}/>:''}
      
      
      {/* Conditionally render main content*/}
      {location.pathname !== '/about' && location.pathname  !=='/sign-up' && location.pathname  !=='/dashboard' && location.pathname  !=='/sandbox' && (
        <>
        {isMobile?(
          // This is only for Mobile
          <div className= 'main-content-mobile'>
          
            <div className='graph-container-mobile'>
              <div className='tradingview-widget-container-mobile'>
                <StockGraph selectedStock={selectedStock}/>
              </div>
              
              {/* <div className='historical-table-container-mobile'>
                <HistoricalTable selectedStock={selectedStock}/>
              </div> */}
            </div>

            <div className='stockscores-container-mobile'>
              <StockScores onStockClick={handleStockClick} triggerSignInPrompt={triggerSignInPrompt} triggerStockScoreInfoPopup={triggerStockScoreInfoPopup} />
            </div>
          
          
          
          
        </div>
        
        ):(
          // This is only for browser
        <div className= 'main-content-mobile'>
          
          <div className='graph-container'>
            <div className='tradingview-widget-container'>
              <StockGraph selectedStock={selectedStock}/>
            </div>
            
            {/* <div className='historical-table-container'>
              <HistoricalTable selectedStock={selectedStock}/>
            </div> */}
          </div>
          
          <div className='stockscores-container'>
            <StockScores onStockClick={handleStockClick} triggerSignInPrompt={triggerSignInPrompt} triggerStockScoreInfoPopup={triggerStockScoreInfoPopup}/>
            
          </div>
          
         
          
          
        </div>
          //old browser content:
        // <div className= 'main-content'>
          
        //   <div className='stockscores-container'>
        //     <StockScores onStockClick={handleStockClick} triggerSignInPrompt={triggerSignInPrompt} triggerStockScoreInfoPopup={triggerStockScoreInfoPopup}/>
            
        //   </div>
          
        //   <div className='graph-container'>
        //     <div className='tradingview-widget-container'>
        //       <StockGraph selectedStock={selectedStock}/>
        //     </div>
            
        //     <div className='historical-table-container'>
        //       <HistoricalTable selectedStock={selectedStock}/>
        //     </div>
        //   </div>
          
          
        // </div>
        )}
        
        </>

        
      )}
      {location.pathname  !=='/sign-up' && location.pathname  !=='/dashboard' && location.pathname  !=='/sandbox' && (
      <div className='welcome-statement-container'>
        <div className='welcome-statement-header'>
          <h1> Welcome to StockRift</h1>
        </div>
        <div className='welcome-statement-paragraph'>
          <p style={{ textIndent: '5%' , lineHeight:'1.5'}}>
          Welcome to StockRift, your go-to platform for stock movement predictions and 
          trading insights. Our unique algorithm analyzes historical data and stock patterns to 
          provide you with a suggested entry price and a projected direction (up or down) for the 
          next trading day. Whether you're an experienced trader or just starting out, our tool 
          offers valuable insights to help you make informed decisions. By focusing on technical 
          analysis and price action, we aim to give you a clear view of potential market trends, 
          enabling you to time your trades better and increase your chances of success.
          </p>

          <p style={{ textIndent: '5%' , lineHeight:'1.5'}}>
          Our easy-to-use interface allows you to quickly navigate through stock tickers, and 
          understand the projected direction of stocks at a glance. Simply click on a stock ticker
           in the table, and you’ll instantly see the suggested entry price for the next trading 
           day along with the predicted movement. Our platform is designed to help you stay ahead 
           of the market, giving you actionable insights that can enhance your trading strategy. 
           Whether you're tracking popular stocks or niche options, our predictions aim to 
           support your trading journey with data-driven forecasts.
          </p>

          <p style={{ textIndent: '5%' , lineHeight:'1.5'}}>
          Currently our data updates daily at 4 PM EST, and the update completes before 4:30 PM.
          We are working to add features that any users request, so please go to our 
          <a rel="icon" href="/about" style={{ color: 'rgb(0, 122, 255)' }}> About Us </a> 
          page to fill out a very short form with feedback and/or feature requests. Thank you so
          much for using this platform!
          </p>
        

        </div>
        
      </div>
      )}
      {location.pathname  !=='/sign-up' && (
        <div className='disclaimer'>
          <p>Please give us feedback {' '}
            <a rel="icon" href="/about" style={{ color: 'rgb(0, 122, 255)' }}>here</a>!
            </p> 
        
        <br />
          <p>The predictions provided by this app are for informational purposes only and should not be considered 
          financial advice. Investing in stocks involves risk, and past performance is not indicative of future 
          results. It is strongly recommended to consult with a financial advisor before making any investment 
          decisions based on this information.</p>
        </div>
      )}
          <Analytics mode={'production'} />
      </div>
      
  );
};

export default App;