import React from "react";
import { useState,useEffect  } from 'react';
import {auth,db,app} from '../components/FirebaseSetUp'
import FirebaseSignIn from '../components/FirebaseSignIn';
import { getFirestore,collection , getDocs, doc, getDoc, FieldValue,updateDoc,arrayRemove,setDoc} from "firebase/firestore";
import SignInPrompt from "./SignInPrompt";
import { FaMinusCircle } from "react-icons/fa";
import { size } from "lodash";
// const { getFirestore, Timestamp, FieldValue, Filter } = require('firebase/firestore');

const RemoveFromWatchlist = ({ticker,triggerSignInPrompt, refreshWatchlist}) => {
  
    // const [user, setUser] = useState(null);
    // const [db,setDb] = useState(null)
    
    const RemoveTickerFromWatchlist = async () => {
      try {
        const currentUser = auth.currentUser; // Get the current user
        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.uid); // Reference the user's document by uid
          const userDocSnap = await getDoc(userDocRef); // Get the document snapshot
  
          if (userDocSnap.exists()) {
            // Document exists, update the watchlist
            await updateDoc(userDocRef, {
              watchlist: arrayRemove(ticker), // Remove the ticker to the watchlist
              
            });
            refreshWatchlist();
            console.log(`Ticker ${ticker} added to watchlist`);
          } else {
            // No document, create a new one
            await setDoc(userDocRef, {
              watchlist: [ticker], // Initialize the watchlist with the ticker
              createdAt: new Date(),
            });
            refreshWatchlist();
            console.log(`New document created and ticker ${ticker} added to watchlist`);
          }
        } else {
          console.log('No user is signed in');
          /*need to prompt user to sign in */
          triggerSignInPrompt();
        }
      } catch (error) {
        console.error('Error getting to watchlist:', error);
      }
    };

    return(
        <div>
            <FaMinusCircle onClick={RemoveTickerFromWatchlist} size={"18"} color="rgb(191, 60, 55)"/>
            {/* <button onClick={RemoveTickerFromWatchlist}> <FaMinusCircle /> </button> */}
        </div>
    );
};

export default RemoveFromWatchlist;