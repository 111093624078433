import React from "react";
import { useState,useEffect  } from 'react';
import { Analytics } from '@vercel/analytics/react';
import {auth,db,app} from '../components/FirebaseSetUp'
import FirebaseSignIn from '../components/FirebaseSignIn';
import { getFirestore } from "firebase/firestore";
import { isMobile } from 'react-device-detect';
import './Dashboard.css'
import StockScoreInfoPopup from '../components/StockScoreInfoPopup';

import StockScores from '../components/StockScores';
import StockGraph from '../components/StockGraph';
import HistoricalTable from '../components/HistoricalTable';
import ConnectBrokerage from "../components/ConnectBrokerage";



function Dashboard()  {

    const [user, setUser] = useState(null);
    const [db,setDb] = useState(null)
    const [loading, setLoading] = useState(true); // Loading state for user auth
    const [signInPrompt, setSignInPrompt] = useState(false);
    const [stockScoreInfoPopup, setStockScoreInfoPopup] = useState(false);

    const [selectedStock, setSelectedStock] = useState('AAPL');

    // Listen to the authentication state and set the user
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setDb(getFirestore(app))
            setLoading(false); // Set loading to false once we know the user state
        });

        // Cleanup the subscription on unmount
        return () => unsubscribe();
    }, []);

    const triggerStockScoreInfoPopup = () => {
        setStockScoreInfoPopup(true);
      };
      const closeStockScoreInfoPopup = () => {
        setStockScoreInfoPopup(false);
      };

    // While loading, show a loader
    if (loading) {
        return <div>Loading...</div>; // You can style this as you like
    }

    const handleStockClick = (stock) => {
        setSelectedStock(stock);
      };
    const triggerSignInPrompt = () => {
        setSignInPrompt(true);
    };
    const closeSignInPrompt = () => {
        setSignInPrompt(false);
    };


    return (
        <div className="dashboard">
            {stockScoreInfoPopup ? <StockScoreInfoPopup closeStockScoreInfoPopup = {closeStockScoreInfoPopup}/>:''}
            {
            user ? 
            <>
            <h3>Welcome {user.email}!</h3>
            </>
            :
            <>
            <div className="dashboard-sign-in-prompt">
                <h2>Welcome, please sign in!</h2>
                <FirebaseSignIn/>
            </div>
            </>
            }
            {user &&(
            <div className="dashboard-container">
            
                {isMobile?(
                    // This is only for Mobile
                    <div className= 'main-content-mobile'>
                    
                        <div className='graph-container-mobile'>
                        <div className='tradingview-widget-container-mobile'>
                            <StockGraph selectedStock={selectedStock}/>
                        </div>
                        
                        {/* <div className='historical-table-container-mobile'>
                            <HistoricalTable selectedStock={selectedStock}/>
                        </div> */}
                        </div>

                        <div className='stockscores-container-mobile'>
                        <StockScores onStockClick={handleStockClick} triggerSignInPrompt={triggerSignInPrompt} triggerStockScoreInfoPopup={triggerStockScoreInfoPopup} showOnlyWatchlist ={true} />
                        </div>
                    
                    
                    
                    
                    </div>
            
                    ):(
                        <div className= 'main-content-mobile'>
                    
                        <div className='graph-container-mobile'>
                            <div className='tradingview-widget-container-mobile'>
                                <StockGraph selectedStock={selectedStock}/>
                            </div>
                        
                        {/* <div className='historical-table-container-mobile'>
                            <HistoricalTable selectedStock={selectedStock}/>
                        </div> */}
                        </div>

                        <div className='stockscores-container-mobile'>
                        <StockScores onStockClick={handleStockClick} triggerSignInPrompt={triggerSignInPrompt} triggerStockScoreInfoPopup={triggerStockScoreInfoPopup} showOnlyWatchlist ={true} />
                        </div>
                    
                    
                    
                    
                    </div>
                        
                    // This is only for browser
                    // <div className= 'main-content'>
                    
                    //     <div className='stockscores-container'>
                    //         <StockScores onStockClick={handleStockClick} triggerSignInPrompt={triggerSignInPrompt} triggerStockScoreInfoPopup={triggerStockScoreInfoPopup} showOnlyWatchlist ={true} />
                            
                    //     </div>
                        
                    //     <div className='graph-container'>
                    //         <div className='tradingview-widget-container'>
                    //         <StockGraph selectedStock={selectedStock}/>
                    //         </div>
                            
                    //         <div className='historical-table-container'>
                    //         <HistoricalTable selectedStock={selectedStock}/>
                    //         </div>
                    //     </div>
                    
                    
                    // </div>
                )}
        </div>
            )}
            
        <Analytics mode={'production'} />
        </div>
    );
}
 
export default Dashboard;