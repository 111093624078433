import React from 'react'
import FirebaseSignIn from './FirebaseSignIn'
import './StockScoreInfoPopup.css'
import { isMobile } from 'react-device-detect';

const StockScoreInfoPopup = ({closeStockScoreInfoPopup}) => {
  return (
    <div>
      {isMobile?(
        <div className='stockscore-info-prompt-mobile'>
        <div className='stockscore-info-prompt-inner-mobile'>
            
        <h2>The Stock Scores Table</h2>
      
        <div className='field-info'>
        <h3>Symbol: </h3>
        <p>&nbsp;The ticker or short name for the stock</p>
        </div>
        <br/>
        <div className='field-info'>
        <h3>Volume: </h3>
        <p>&nbsp;Daily trading volume</p>
        </div>
        <br/>
        <div className='field-info'>
        <h3>Change % at Close: </h3>
        <p>&nbsp;The change in price from open to close of the most recent trading day</p>
        </div>
        <br/>
        <div className='field-info'>
        <h3>Entry Price: </h3>
        <p>&nbsp;The price which our algorithm came up with the prediction at. 
          So for example, if the suggestion says "BUY", that suggestion was made at this price.</p>
        </div>
        <br/>
        <div className='field-info'>
        <h3>Suggestion: </h3>
        <p>&nbsp;Either BUY, SELL, or HOLD, indicating our algorithm's prediction for the direction of the stock in the trading day to follow.</p>
        </div>
        <br/>
        <div className='field-info'>
        <h3>Prediction Accuracy: </h3>
        <p>&nbsp;How accurate previous predictions have been for this particular ticker.</p>
        </div>
        <br/>
        <div>
            <button onClick={closeStockScoreInfoPopup}>Close</button>
        </div>

    </div>
    </div>

      ):(
        <div className='stockscore-info-prompt'>
        <div className='stockscore-info-prompt-inner'>
            
            <h2>The Stock Scores Table</h2>
            
            <br/>
            <div className='field-info'>
            <h3>Symbol: </h3>
            <p>&nbsp;The ticker or short name for the stock</p>
            </div>
            <br/>
            <div className='field-info'>
            <h3>Volume: </h3>
            <p>&nbsp;Daily trading volume</p>
            </div>
            <br/>
            <div className='field-info'>
            <h3>Change % at Close: </h3>
            <p>&nbsp;The change in price from open to close of the most recent trading day</p>
            </div>
            <br/>
            <div className='field-info'>
            <h3>Entry Price: </h3>
            <p>&nbsp;The price which our algorithm came up with the prediction at. 
              So for example, if the suggestion says "BUY", that suggestion was made at this price.</p>
            </div>
            <br/>
            <div className='field-info'>
            <h3>Suggestion: </h3>
            <p>&nbsp;Either BUY, SELL, or HOLD, indicating our algorithm's prediction for the direction of the stock in the trading day to follow.</p>
            </div>
            
            <br/>
            <div>
                <button onClick={closeStockScoreInfoPopup}>Close</button>
            </div>

        </div>
        </div>
      )};
        
      
    </div>
  );
}

export default StockScoreInfoPopup
